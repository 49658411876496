import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import { LocationInfo } from '@zupr/web/src/js/frontoffice/screens/location'

import { inShoppingArea, redirectToShoppingArea } from '.'
import { getLocation, locationMeta } from '../../../helpers/location'
import { getProps } from '../../../helpers/props'

export async function getServerSideProps({ req, params, query }) {
    const siteProps = await getProps({ req, params, query })

    const location = await getLocation({ locationId: params.locationId, ...siteProps }, req)

    if (!location) {
        return {
            notFound: true,
        }
    }

    if (!inShoppingArea(location, siteProps)) {
        return await redirectToShoppingArea(location, req)
    }

    const meta = locationMeta({ location, ...siteProps })

    return {
        props: {
            ...siteProps,
            meta,
            location,
        },
    }
}

const Index = ({ location, ...props }: any) => (
    <Providers {...props}>
        <Frontoffice>
            <LocationInfo location={location} />
        </Frontoffice>
    </Providers>
)

export default Index
